
  import { Vue, Component } from 'vue-property-decorator'
  import getApi from '@/api'
  import { namespace } from 'vuex-class'
  import { UserInterface, QuizInterface, UserAnswerInterface } from '@/interface/BaseInterface'
  import QuizApi from '../../api/quizApi'
  import QuizTestResult from '../../components/quizPreview/QuizTestResult.vue'
  import QuizAttempt from '../../api/quizAttempt'

  const tAuth = namespace('tAuth')
  const tQuizResult = namespace('tQuizResult')
  const quizApi: QuizApi = getApi('quiz')
  const quizAttempt: QuizAttempt = getApi('quizAttempt')

  @Component({
    components: { QuizTestResult }
  })
  export default class QuizResult extends Vue {
    @tAuth.State('user') user!: UserInterface

    @tQuizResult.Action('setAttemptResult') setAttemptResult!: (data: UserAnswerInterface[]) => void

    quiz: QuizInterface = {
      _id: this.quizId,
      name: '',
      courseId: this.courseId,
      sectionId: '',
      parts: []
    }
    // attempt: UserAnswerInterface[] = []

    async init() {
      try {
        this.quiz = await quizApi.getById(this.quizId)
        if (this.$route.params.userId) {
          this.setAttemptResult(await quizAttempt.attemptDetailByUser(this.quizId, this.attemptId, this.$route.params.userId))
        } else {
          this.setAttemptResult(await quizAttempt.attemptDetail(this.quizId, this.attemptId))
        }
      } catch (error) {
        console.log(error)
      }
    }

    created() {
      this.init()
    }
  }
